<template>
  <main class="page-container" id="sme-we-view">
    <div class="page-component">
      <div class="page-header screen-out">
        <div class="page-component">
          <div class="page-header-title">
            <h2 class="title">우리, 소상공인</h2>
          </div>
        </div>
      </div>
      <div class="page-body" style="position: relative">
        <section class="post-view-wrapper">
          <header class="post-view-header">
            <div class="header-category">
              <div class="header-issue">
                <span class="issue-text">ISSUE {{ params.issueNo }}</span>
              </div>
            </div>
            <h3 class="header-title">{{ params.issueTit }}</h3>
          </header>
          <div class="post-view-body">
            <div class="gachi-view" v-html="params.issueCn"></div>
          </div>
        </section>
        <div v-if="isMobile" class="page-buttons" data-align="center" style="flex-direction: row;width: 100%;margin: auto">
          <a href="javascript:">
            <next-prev-button
                route-name="IssueView"
                param-key="issueId"
                :pre-id="params.preId"
                :next-id="params.nextId"
            ></next-prev-button>
          </a>
        </div>
        <div v-else class="page-buttons" data-align="center" style="flex-direction: row;width: 900px;margin: auto">
          <a href="javascript:">
            <next-prev-button
                route-name="IssueView"
                param-key="issueId"
                :pre-id="params.preId"
                :next-id="params.nextId"
            ></next-prev-button>
          </a>
        </div>
        <a href="javascript:">
          <div v-if="isMobile" class="list-btn-cont" style="width: 100px;position: absolute;left: calc(50% - 50px);top: calc(100% - 80px);">
            <router-link :to="{name: 'Issue'}" class="btn btn-lg btn-primary" >
              <span class="text">목록</span>
            </router-link>
          </div>
          <div v-else class="list-btn-cont" style="width: 120px;position: absolute;left: calc(50% - 60px);top: calc(100% - 60px);">
            <router-link :to="{name: 'Issue'}" class="btn btn-lg btn-primary" >
              <span class="text">목록</span>
            </router-link>
          </div>
        </a>
      </div>
    </div>
  </main>
</template>

<script>
import {ACT_GET_ISSUE} from '../../../store/_act_consts';
import {getItem, lengthCheck, queryToValue, setParams} from '../../../assets/js/utils';
import {viewCount} from '../../../assets/js/func';
import NextPrevButton from '../../../components/common/NextPrevButton';
import {mapGetters} from "vuex";

export default {
  name: 'IssueView',
  components:{
    NextPrevButton
  },
  watch:{
    $route(to){
      this.params.issueId = queryToValue(to.params.issueId, true, 0);
      this.getIssue();
    }
  },
  data:()=> ({
    params: {
      issueId: 0,
      issueNo: 0,
      issueTit: '',
      issueSmlTit: '',
      issueCn: '',
      preId: 0,
      nextId: 0
    }
  }),
  computed: {
    ...mapGetters('common', ['isMobile'])

  },
  created() {
    this.params.issueId = this.$route.params.issueId;
    viewCount(this.issueId, 'issue');
    this.getIssue();
  },
  methods: {
    getIssue() {
      this.$store.dispatch(`information/${ACT_GET_ISSUE}`, this.params.issueId)
      .then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(this.params, item);
        }else {
          this.issueId = 0;
        }
      }).catch(e=>{
        console.error(e);
        this.issueId = 0;
      })
    },
    // clickMove(type){
    //   const paramId = (type === 'prev' ? this.params.preId : this.params.nextId);
    //   this.$router.push({name: 'IssueView', params: {issueId: paramId}}).catch(()=>{});
    // }
  }
};
</script>
